<template>
    <div class="soc-real-time">
        <div>
            <van-circle v-model="currentRate" color="#4DD3D0" :stroke-width="80" layer-color="#F7F8FA" :rate="currentRate" :speed="100">
                <template slot="default">
                    <div class="text">
                        <div><p>{{currentRate}}</p>%</div>
                        <span>SOC</span>
                    </div>
                </template>
            </van-circle>
        </div>
        <div ref="charts">

        </div>
    </div>
</template>
<script>
    import 'echarts-liquidfill'
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
    export default {
        data()
        {
            return {
                currentRate: 86.3,
				name: '实时功率',
                option: {
                    grid: {
                        left: '0%',
                        right: '0%',
                        top: '0%',
                        bottom: '0%',
                        containLabel: true
                    },
                    title:{
                        text: '',
                        textStyle: {
                            show: false
                        }
                    },
                    series:[{
                        type: 'liquidFill',
                        radius: '100%',
                        center: ['50%','50%'],
                        data: [0.5, {
                            value: 0.5,
                            derection: 'left'
                        }],
                        outline: {
                            borderDistance: 0,
                            itemStyle: {
                                borderWidth: 1,
                                borderColor: '#AFF0EB'
                            }
                        },
                        backgroundColor: {
                            borderWidth: 1,
                            color: '#ffffff'
                        },
                        itemStyle: {
                            shadowBlur: 0
                        },
                        label: {
                            normal: {
                                formatter: '{a|25}{hr|kw}\n{hr|'+name+'}',
                                rich: {
                                    a: {
                                        color: 'rgba(0,0,0,0.9)',
                                        fontSize: 20,
                                        lineHeight: 22,
                                        fontWeight: '800'
                                    },
                                    hr: {
                                        color: 'rgba(0,0,0,0.9)',
                                        fontSize: 14,
                                        lineHeight: 22,
                                        fontWeight: '400'
                                    }
                                },
                            }
                        },
                        backgroundStyle: {
                            color: '#fff'
                        },
                        color: ['#AFF0EB', '#4DD3D0']
                    }]
                }
            }
        },
        methods: {
			// 获取实时功率
            initChart() {
                let myEcharts = this.$echarts.init(this.$refs.charts)
				dataInterface
					.dataFaceApi({
						"enCode": "solar-stored-overview-middle-screen-info", // 接口code
						"@station_id": localStorage.getItem('stationId') //  站点id 例如621025075751045445
					})
					.then((res) => {
						if(res[0]){
							this.option.series[0].label.normal.formatter = '{a|'+ res[0].power +'}{hr|kw}\n{hr|'+this.name+'}'
							myEcharts.setOption(this.option)
						}
						
					})
					.catch((err) => {
						console.log(err);
					});
               
            },
			// 获取数据
			getData() {
				dataInterface
					.dataFaceApi({
						"enCode": "solar-stored-overview-middle-screen-info", // 接口code
						"@station_id": localStorage.getItem('stationId') //  站点id 例如621025075751045445
					})
					.then((res) => {
						try{
							this.currentRate = res ? res[0].soc : 22
							this.currentRate = this.currentRate * 1
						}catch(e){
							//TODO handle the exception
						}
						
					})
					.catch((err) => {
						console.log(err);
					});
			}
        },
        mounted() {
			if(localStorage.getItem("lang") == 'en'){
				this.name = 'Realtimepower'
			} else {
				this.name = '实时功率'
			}
            this.initChart()
			this.getData()
			
        },
    }
</script>
<style lang="scss">
    .soc-real-time {
        display: flex;
        margin-top: 12px;
        height: 122px;
        padding: 0 12px;
		justify-content: space-between;
        > div {
            width: 49%;
            background: #FFFFFF;
            border-radius: 16px 16px 16px 16px;
            padding: 12px;
        }
        >div:first-child {
            margin-right: 12px;
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        .text {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            > div {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: rgba(0,0,0,0.9);  
                margin-bottom: 3px;
                p {
                    font-size: 20px;
                }       
            }
            > span {
                color: rgba(0,0,0,0.7);
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
</style>