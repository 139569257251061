<template>
	<div class="over-view">
		<van-nav-bar :title="langset.index" >
			<template #right>
				<van-icon name="setting-o" color="#00ADB5" size="18"  @click="showpopup = true"/>
			</template>
		</van-nav-bar>
		<div class="over-main" v-if="showPage">
			<div class="home-tabber">
				<div class="tab-box">
					<div class="cell" @click="changeTap('0')" :class="{'active': active == '0'}">{{langset.Lightstorage}}</div>
					<div class="cell" @click="changeTap('1')" :class="{'active': active == '1'}">{{langset.Energystorage}}</div>
					<div class="cell" @click="changeTap('2')" :class="{'active': active == '2'}">{{langset.photovoltaic}}</div>
				</div>
			</div>
			<div class="light-storage" v-if="active == 0">
				<site></site>
				<dailyEarnings></dailyEarnings>
				<socRealTime></socRealTime>
				<totalEarnings></totalEarnings>
				<pvDevice></pvDevice>
				<monitorComponents :title="langset.Powerstationrevenue"></monitorComponents>
				<energyDischarging :title="langset.Energystoragedischargemonitoring"></energyDischarging>
				<photovoltaicGeneration :title="langset.Photovoltaicpowergenerationmonitoring"></photovoltaicGeneration>
				<contribute></contribute>
			</div>
			<div class="light-storage" v-if="active == 1">
				<energysite></energysite>
				<energydailyEarnings></energydailyEarnings>
                <electricityLevel></electricityLevel>
				<energytotalEarnings></energytotalEarnings>
				<energypvDevice></energypvDevice>
				<energymonitorComponents :title="langset.Powerstationrevenue"></energymonitorComponents>
				<energyenergyDischarging :title="langset.Charginganddischargingtrends"></energyenergyDischarging>
				<energyphotovoltaicGeneration :title="langset.Electricitypricetrend"></energyphotovoltaicGeneration>
			</div>
			<div class="light-storage" v-if="active == 2">
				<pvsite></pvsite>
                <weather></weather>
				<pvdailyEarnings></pvdailyEarnings>
				<dailyRelease></dailyRelease>
				<pvtotalEarnings></pvtotalEarnings>
				<pvpvDevice></pvpvDevice>
				<pvphotovoltaicGeneration :title="langset.Powerstationrevenue"></pvphotovoltaicGeneration>
				<pvmonitorComponents :title="langset.Realtimepowermonitoring"></pvmonitorComponents>
				<pvcontribute></pvcontribute>
			</div>
		</div>
		<van-popup v-model="showpopup" round  position="bottom">
			<div class="choose-box">
				<div class="header">
					<div class="left">{{langset.Filter}}</div>
					<div class="right" @click="showpopup = false">
						<van-icon name="cross"  color="#00ADB5" size="18"/>
					</div>
				</div>
				<div class="sub-box">
					<div class="title">{{langset.language}}</div>
					<div class="list-box">
						<div class="cell" @click="changeLang('cn')" :class="{'active': lang == 'cn'}">{{langset.cn}}</div>
						<div class="cell" @click="changeLang('en')" :class="{'active': lang == 'en'}">{{langset.en}}</div>
					</div>
					
					<div class="title">{{langset.currency}}</div>
					<div class="list-box">
						<div class="cell active">{{langset.RMB}}</div>
						<div class="cell">{{langset.dollar}}</div>
						<div class="cell">{{langset.peso}}</div>
						<div class="cell">{{langset.Taiwancurrency}}</div>
						<div class="cell">{{langset.euro}}</div>
						<div class="cell">{{langset.HongKongdollars}}</div>
						<div class="cell">{{langset.JapaneseYen}}</div>
					</div>
				</div>
				<button class="button" @click="confirm">{{langset.confirm}}</button>
			</div>
		</van-popup>
	</div>
</template>

<script>
	//引入api
	import {dataInterface,getstations} from '@/api/dataInterfaceApi';
	// 站点组件
	import site from '@/components/site.vue'
	// 收益组件
	import dailyEarnings from '@/components/dailyEarnings.vue'
	// soc, 实时收益组件
	import socRealTime from '@/components/socRealTime.vue'
	// 总收益组件
	import totalEarnings from '@/components/totalEarnings.vue'
	// 光储设备组件
	import pvDevice from '@/components/pvDevice.vue'
	// 监控组件
	import monitorComponents from '@/components/monitorComponents.vue'
	// 储能放电检测
	import energyDischarging from '@/components/energyDischarging.vue'
	// 光伏发电
	import photovoltaicGeneration from '@/components/photovoltaicGeneration.vue'
	// 社会贡献组件
	import contribute from '@/components/contribute.vue'
	// 站点组件
	import energysite from '@/components/energy/site.vue'
	// 收益组件
	import energydailyEarnings from '@/components/energy/dailyEarnings.vue'
	// soc, 实时收益组件
	import energysocRealTime from '@/components/energy/socRealTime.vue'
	// 总收益组件
	import energytotalEarnings from '@/components/energy/totalEarnings.vue'
	// 光储设备组件
	import energypvDevice from '@/components/energy/pvDevice.vue'
	// 监控组件
	import energymonitorComponents from '@/components/energy/monitorComponents.vue'
	// 储能放电检测
	import energyenergyDischarging from '@/components/energy/energyDischarging.vue'
    // 光伏发电
	import energyphotovoltaicGeneration from '@/components/energy/photovoltaicGeneration.vue'
    // 电量组件
    import electricityLevel from '@/components/energy/electricityLevel.vue'
    // 站点组件
	import pvsite from '@/components/pv/site.vue'
	// 收益组件
	import pvdailyEarnings from '@/components/pv/dailyEarnings.vue'
	// soc, 实时收益组件
	import pvsocRealTime from '@/components/pv/socRealTime.vue'
	// 总收益组件
	import pvtotalEarnings from '@/components/pv/totalEarnings.vue'
	// 光储设备组件
	import pvpvDevice from '@/components/pv/pvDevice.vue'
	// 监控组件
	import pvmonitorComponents from '@/components/pv/monitorComponents.vue'
	// 储能放电检测
	import pvDischarging from '@/components/pv/energyDischarging.vue'
    // 光伏发电
	import pvphotovoltaicGeneration from '@/components/pv/photovoltaicGeneration.vue'
	// 社会贡献组件
	import pvcontribute from '@/components/pv/contribute.vue'
    // 天气组件
	import weather from '@/components/pv/weather.vue'
    // 日发电量
	import dailyRelease from '@/components/pv/dailyRelease.vue'
	//引入mixin
	import mixin from '@/utils/mixin'
	//引入首页中英文文件
	import langObj from '@/utils/overviewLnag'
	
	export default {
		mixins: [mixin],
		components: {
			site,
			dailyEarnings,
			socRealTime,
			totalEarnings,
			pvDevice,
			monitorComponents,
			energyDischarging,
			photovoltaicGeneration,
			contribute,
			energysite,
			energydailyEarnings,
			energysocRealTime,
			energytotalEarnings,
			energypvDevice,
			energymonitorComponents,
			energyenergyDischarging,
			energyphotovoltaicGeneration,
            electricityLevel,
            pvsite,
			pvdailyEarnings,
			pvsocRealTime,
			pvtotalEarnings,
			pvpvDevice,
			pvmonitorComponents,
			pvDischarging,
			pvphotovoltaicGeneration,
			pvcontribute,
            weather,
            dailyRelease
		},
		data() {
			return {
				active: 0,
				showpopup: false,
				lang: 'cn',
				showPage: true
			}
		},
		methods: {
			// 修改语言
			changeLang(type) {
				this.lang = type
				localStorage.setItem("lang", type)
			},
			// 确认修改语言和货币
			confirm() {
				this.$router.go(0)
			},
			// 获取站点第一个数据初始化
			getData(value,resolve,reject) {
				this.showPage = false
				dataInterface
					.getstations({
						type: value
					})
					.then((res) => {
						if(res){
							if(value == 1) {
								localStorage.setItem("stationId", res.list[0].id)
							} else if(value == 2) {
								localStorage.setItem("stationPvId", res.list[0].id)
							} else if(value == 3) {
								localStorage.setItem("stationPStorageId", res.list[0].id)
							}
							resolve(res)
						}
					})
					.catch((err) => {
						reject(err)
					});
			},
			changeTap(value) {
				this.active = value
			}
		},
		created() {
			this.active = localStorage.getItem("tabItem") ? localStorage.getItem("tabItem") : 0
			console.log(localStorage.getItem("tabItem") , '8888888888888')
			console.log(this.active, '8888888888888')
			let that = this
			Promise.all([
				new Promise((resolve, reject) => {
					// 如果没有光储能站点初始化第一个站点
					if(!localStorage.getItem("stationId")){
						this.getData(1,resolve,reject)
					}	
				}),
				
				new Promise((resolve, reject) => {
					// 如果没有光储能站点初始化第一个站点
					if(!localStorage.getItem("stationPvId")){
						this.getData(2,resolve,reject)
					}	
				}),
				
				new Promise((resolve, reject) => {
					// 如果没有光储能站点初始化第一个站点
					if(!localStorage.getItem("stationPStorageId")){
						this.getData(3,resolve,reject)
					}	
				})
				
				
			]).then(result => {
				that.showPage = true
				console.log(result, '000000000000000000')
			})
			
		},
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
		},
		beforeDestroy() {
			localStorage.setItem("tabItem", this.active)
		}
	}
</script>
<style lang="scss">
	.over-view {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		.choose-box{
			width: 100%;
			background-color: #FFFFFF;
			width: 100vw;
			border-radius: 10px;
			padding: 20px;
			.header{
				height: 30px;
				display: flex;
				font-size: 16px;
				.left{
					flex: 1;
				}
				.right{
					width: 60px;
					text-align: right;
				}
			}
			.sub-box{
				.title{
					height: 20px;
					color: rgba(0, 0, 0, 0.50);
				}
				.list-box{
					display: flex;
					margin-top: 14px;
					flex-wrap: wrap;
					.cell{
						margin-bottom: 10px;
						padding: 8px 17px;
						color: rgba(0, 0, 0, 0.70);
						border: 1px solid rgba(134, 144, 156, 1);
						border-radius: 8px 8px 8px 8px;
						margin-right: 20px;
						
					}
					.active{
						background: #00ADB5;
						color: #FFFFFF;
					}
				}
			}
			.button{
				width: 96%;
				margin-left: 2%;
				height: 48px;
				background: #00ADB5;
				margin-top: 20px;
				border: none;
				color: #ffffff;
				box-shadow: 0px 2px 8px 0px rgba(77,211,208,0.2), 0px 12px 20px 0px rgba(0,142,155,0.14);
				border-radius: 43px 43px 43px 43px;
			}
		}
		.over-main {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;

			>.home-tabber {
				height: 48px;
				padding: 2px 0;
				background: #fff;

				.van-tabs__wrap {
					height: 44px;
					box-sizing: border-box;
				}

				.van-tabs__line {
					display: none;
				}

				.van-tabs__nav {
					height: 40px;
					padding-bottom: 0px;
					padding: 0 2px;
					margin: 0 12px;
					border-radius: 20px 20px 20px 20px;
					background: #F7F8FA;
				}

				.van-tab {
					height: 40px !important;
					line-height: 40px;
					margin: 0 2px;
				}

				.van-tab--active {
					width: 119px;
					height: 36px;
					background: #00ADB5;
					border-radius: 20px;

					.van-tab__text {
						font-weight: bold;
						font-size: 16px;
						color: #FFFFFF;
						line-height: 24px;
					}
				}
				
				.tab-box{
					width: 100%;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 20px;
					background-color: #F7F8FA;
					color: rgba(0,0,0,0.9);
					display: flex;
					.cell{
						flex: 1;
					}
					.active{
						border-radius: 20px;
						color: #FFFFFF;
						background-color: #00ADB5;
					}
				}
			}

			.light-storage {
				flex: 1;
				overflow: auto;
			}

			.light-storage::-webkit-scrollbar {
				display: none;
			}
		}
	}

	.van-nav-bar__content {
		height: 44px !important;

		.van-nav-bar__title {
			font-size: 18px;
			line-height: 26px;
		}
	}

	.van-hairline--bottom::after {
		border-bottom-width: 0 !important;
	}
</style>