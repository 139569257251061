<template>
    <div class="monitor">
        <div class="title">{{ title }}</div>
        <div class="home-tabber">
			<div class="tab-box">
				<div class="cell" @click="showDate('day')" :class="{'active': active == 'day'}">{{langset.day}}</div>
				<div class="cell" @click="showDate('month')" :class="{'active': active == 'month'}">{{langset.month}}</div>
				<div class="cell" @click="showDate('year')" :class="{'active': active == 'year'}">{{langset.year}}</div>
				<div class="cell" @click="showDate('more')" :class="{'active': active == 'more'}">{{langset.more}}</div>
			</div>
            <div class="monitor-date">
                <p v-if="active == 'more'">{{ moreDateDes }}</p>
            </div>
            <div class="monitor-date" v-if="show">
                <van-calendar v-model="show" :close-on-click-overlay="false" type="range" color="#00ADB5" @confirm="onConfirm" />
            </div>
            <!-- 检测图表插槽 -->
            <div class="unit">
                <p>{{langset.unit}}：{{langset.Yuan}}</p>
                <div>
                    <p>
                        <span></span>
                        {{langset.chargePrice}}
                    </p>
                    <p>
                        <span></span>
                        {{langset.dischargePrice}}
                    </p>
                </div>
            </div>
        </div>
		<div class="plantsprofits">
		    <div class="charts" style="width: 100%;height: 155px;margin-top: 10px" ref="charts"></div>
		</div>
    </div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
    export default {
		mixins: [mixin],
        props: {
            title: String,
            unit: String
        },
        data() {
            return {
				dataList: [],
                active: 'day',
                value: '',
				moreDateDes: '',
                moreDate: [],
                show: false,
				showconfirm: false,
				option: {
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        left: '0%',
                        right: '4%',
                        top: '10%',
                        bottom: '0%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['4月', '5月', '6月', '7月', '8月', '9月', '10月']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: function(arg) {
                            var div = `<div 
                            style="width: 137px; background: rgba( 255, 255, 255, .5);">
                                <p 
                                style="font-family: PingFang SC, PingFang SC;
                                font-weight: bold;
                                font-size: 14px;
                                color: rgba(0,0,0,0.9);
                                line-height: 22px; 
                                margin-bottom: 4px;">2024年9月</p>
                                <div 
                                style="display: flex; 
                                align-items: center; 
                                line-height: 20px;
                                margin-bottom: 4px;">
                                <div 
                                    style="width: 10px;
                                    height: 10px;
                                    background: #FFFFFF;
                                    box-shadow: 0px 11px 12px 0px rgba(41,198,200,0.25);
                                    border-radius: 11px 11px 11px 11px;
                                    border: 3px solid #00ADB5;
                                    margin-right: 4px;">
                                </div>
                                    充电单价
                                <p style="flex: 1; text-align: right;">
                                    <span style="font-weight: bold">3</span>元/度
                                </p>
                                </div>
                                <div 
                                style="display: flex; 
                                align-items: center; 
                                line-height: 20px;">
                                <div 
                                    style="width: 10px;
                                    height: 10px;
                                    background: #FFFFFF;
                                    box-shadow: 0px 11px 12px 0px rgba(41,198,200,0.25);
                                    border-radius: 11px 11px 11px 11px;
                                    border: 3px solid #FF9A2E;
                                    margin-right: 4px;">
                                </div>
                                    放电单价
                                <p style="flex: 1; text-align: right;">
                                    <span style="font-weight: bold">3.3</span>元/度
                                </p>
                                </div>
                            </div>`
                        return div
                        }
                    },
                    series: [
                        {
                            type: 'line',
                            step: 'middle',
                            stack: 'Total',
                            data: [120, 132, 101, 134, 90, 230, 210],
                            itemStyle: {
                                normal: {
                                    color: '#FF9A2E', 
                                    lineStyle: {
                                        color: '#FF9A2E' // 折线颜色
                                    }
                                }
                            }
                        },
                        {
                            type: 'line',
                            step: 'middle',
                            stack: 'Total',
                            data: [220, 182, 191, 234, 290, 330, 310],
                            itemStyle: {
                                normal: {
                                    color: '#00ADB5', // 点的颜色
                                    lineStyle: {
                                        color: '#00ADB5' // 线的颜色
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        },
		watch: {},
        methods: {
            showDate(type) {
				console.log(type)
				if(type == "more") {
					this.show = true
					return 
				}
				this.active = type
				this.initChart()
            },
            rangeForm(date) {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            },
            onConfirm(date) {
                console.log(date)
				const start = this.rangeForm(date[0])
				const end = this.rangeForm(date[1])
				this.moreDate[0] = start
				this.moreDate[1] = end
				this.active = 'more'
				this.show = false
                this.moreDateDes = `${start} 至 ${end}`
				this.initChart()
            },
			initChart() {
			    let myEcharts = this.$echarts.init(this.$refs.charts)
				let date_state = this.active
				if(date_state == 'more'){
					date_state = ""
				}
				dataInterface
					.dataFaceApi({
						"enCode": "stored-overview-chg-dischg-elect-price-tendency",// 接口code
						"@station_id": localStorage.getItem("stationPStorageId"), //  站点id 例如621025075751045445
						"@date_state": date_state, //   例如day，month,year
						"@start_date": this.moreDate[0] ? this.moreDate[0] : '2024-11-8', //  日期的开始范围  自定义
						"@end_date": this.moreDate[1]  ? this.moreDate[1] : '2024-11-9'// 日期的结束范围
					})
					.then((res) => {
						this.dataList = res ? res : [],
						this.option.xAxis.data = []
						this.option.series[0].data = []
						this.dataList.forEach((item) => {
							this.option.xAxis.data.push(item.cur_date)
							this.option.series[0].data.push(item.dischg_elect_price)
							this.option.series[1].data.push(item.chg_elect_price)
							
						})
						myEcharts.setOption(this.option)
					})
					.catch((err) => {
						console.log(err);
					});
			}
        },
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
		    this.initChart()
		}
    }
</script>
<style lang="scss">
    .monitor{
        background: #fff;
        height: 326px;
        background: #FFFFFF;
        border-radius: 16px 16px 16px 16px;
        padding: 12px;
        margin: 0 12px;
        margin-bottom: 12px;
        .title {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            padding: 4px 0 16px 0;
        }
        > .home-tabber {
            padding: 2px 0;
            background: #fff;
            .van-tabs__wrap {
                height: 30px;
                box-sizing: border-box;
            }
            .van-tabs__line {
                display: none;
            }
            .van-tabs__nav {
                height: 30px;
                padding-bottom: 0px;
                padding: 0 2px;
                border-radius: 20px 20px 20px 20px;
                background: #F7F8FA;
            }
            .van-tab {
                height: 30px !important;
                line-height: 30px;
            }
            .van-tab--active {
                width: 119px;
                height: 36px;
                background: #00ADB5;
                border-radius: 20px;
                .van-tab__text {
                    font-weight: bold;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 22px;
                }
            }
            .unit {
                display: flex;
                align-items: center;
                color: rgba(0,0,0,0.45);
                > p {
                    flex: 1;
                }
                div {
                    display: flex;
                    > p {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;
                        span {
                            width: 15px;
                            height: 3px;
                        }
                    }
                    > p:first-child {
                        span {
                            background: #00ADB5;
                        }
                    }
                    > p:last-child {
                        span {
                            background: #FF9A2E;
                        }
                    }
                }
            }
			.tab-box{
				width: 100%;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border-radius: 20px;
				background-color: #F7F8FA;
				color: rgba(0,0,0,0.9);
				display: flex;
				.cell{
					flex: 1;
				}
				.active{
					border-radius: 20px;
					color: #FFFFFF;
					background-color: #00ADB5;
				}
			}
        }
        .monitor-date {
            height: 36px;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            > p {
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000000;
                .van-icon {
                    margin-left: 10px;
                }
            }
        }
    }
</style>