<template>
	<div class="about">
		<van-nav-bar :title="langset.searchPage" >
			<template #left>
				<van-icon name="arrow-left" color="#00ADB5" size="18"  @click="goback"/>
			</template>
		</van-nav-bar>
		<div class="search">
			<van-search v-model="value" show-action placeholder="请输入搜索关键词" action-text="搜索" @search="getData" />
		</div>
		<div class="search-list">
			<div class="cell" @click="setStation(item)" v-for="(item, index) in list" :key="index">{{item.name}}</div>
		</div>
	</div>
</template>
<script>
	//引入api
	import {dataInterface} from '@/api/dataInterfaceApi';
	import {Toast} from 'vant'
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
	export default {
		mixins: [mixin],
		data() {
			return {
				value: '',
				loading: false,
				list: [],
				finished: false
			}
		},
		methods: {
			//返回
			goback() {
				this.$router.go(-1)
			},
			// 路由跳转
			setStation(item) {
				localStorage.setItem('stationPvId', item.id)
				this.$router.replace({
					name: 'overview'
				})
			},
			// 获取站点列表数据
			getData(value) {
				dataInterface
					.getstations({
						type: 2
					})
					.then((res) => {
						if(res){
							this.list = res.list ? res.list : []
						}
					})
					.catch((err) => {
						console.log(err);
					});
					
			}
		},
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			this.getData()
		}
	}
</script>
<style lang="scss">
	.about {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		.search {
			height: 64px;
		}

		.search-list {
			flex: 1;
			overflow: auto;
			padding: 5px 20px;

			.cell {
				background: #F7F8FA;
				line-height: 18px;
				padding: 16px 5px;
				border-bottom: 1px solid #E5E6EB;
				font-size: 16px;
				color: rgba(0, 0, 0, 0.9);
			}
		}

		.van-search__content {
			background: #F7F8FA;
			border-radius: 6px 6px 6px 6px;
		}

		.van-search__action {
			margin: 0 12px 0 16px;
			font-weight: 800;
			color: #00ADB5;
		}
	}
</style>