
 
 //  minxin.js
const mixin = {
  data() {
    return{
      langset:{}
    }
  },
  created() {
    console.log(this.msg3)
  },
  mounted() {
  	// 初始化语言
  	this.lang = localStorage.getItem("lang") ?localStorage.getItem("lang") : 'cn'
  	
  }
}
export default mixin