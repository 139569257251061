<template>
	<div class="home-site">
		<div class="site-name" @click="onClickRight('search')">
			<p>{{stationInfo.name || '-'}}</p>
			<van-icon name="play"/>
		</div>
		<div class="site-info">
			<div class="date">
				<div class="day">
					<p>{{stationInfo.secure_run || '-'}}</p><span>{{langset.day}}</span>
				</div>
				<div class="type-text">{{langset.operation}}</div>
				<div class="type">{{stationInfo.operation_status || '-'}}</div>
			</div>
			<div class="info">
				<div class="info-list"><span>{{langset.region}}：</span>{{stationInfo.area || '-'}}</div>
				<div class="info-list"><span>{{langset.Operationdate}}：</span>{{stationInfo.operation_date || '-'}}</div>
				<div class="info-btn" @click="onClickRight('siteDetails')">{{langset.SiteDetails}}<van-icon name="arrow" color="#00ADB5" /></div>
			</div>
		</div>
	</div>
</template>
<script>
	//引入api
	import { dataInterface } from '@/api/dataInterfaceApi';
	//引入mixin
	import mixin from '@/utils/mixin';
	//引入首页中英文文件
	import langObj from '@/utils/site';
	export default {
		name: 'homeTab',
		mixins: [mixin],
		data() {
			return {
				stationInfo:{}
			}
		},
		methods: {
			// 跳转页面
			onClickRight(page) {
				this.$router.push({
					name: page
				})
			},
			// 获取头部数据
			getData() {
				dataInterface
					.dataFaceApi({
						"enCode": "solar-stored-overview-station-info", // 接口code
						"@station_id": localStorage.getItem('stationId') //  站点id 例如621025075751045445
					})
					.then((res) => {
						console.log(res)
						this.stationInfo = res ? res[0] : {}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		mounted() {
			// 设置语言
			this.langset = langObj[this.lang]
			this.getData()
		}
	}
</script>
<style lang="scss">
	.home-site {
		height: 148px;
		border-radius: 0px 0px 16px 24px;
		padding: 12px;
		background: #fff;
		padding-top: 8px;

		.site-name {
			display: flex;
			height: 32px;
			line-height: 32px;
			padding-left: 3px;
			p {
				flex: 1;
				font-weight: 800;
				font-size: 24px;
				color: rgba(0, 0, 0, 0.9);
			}

			.van-icon {
				line-height: 32px;
			}
		}

		.site-info {
			margin-top: 10px;
			height: 82px;
			display: flex;

			.date {
				width: 72px;
				border-radius: 8px 8px 8px 8px;
				border: 1px solid #E8FFF0;
				display: flex;
				flex-direction: column;
				align-items: center;

				.day {
					display: flex;
					align-items: end;
					font-weight: 400;
					font-size: 20px;
					color: #00B45A;
					line-height: 20px;
					margin-top: 8px;

					span {
						font-weight: 0;
						font-size: 14px;
						line-height: 14px;
					}
				}

				.type-text {
					font-weight: 400;
					font-size: 14px;
					color: rgba(0, 0, 0, 0.7);
					margin-bottom: 8px;
				}

				.type {
					height: 22px;
					background: #E8FFF0;
					border-radius: 0px 0px 0px 0px;
					width: 100%;
					text-align: center;
					font-weight: 400;
					font-size: 14px;
					color: #00B45A;
				}
			}

			.info {
				flex: 1;
				padding-left: 12px;

				.info-list {
					display: flex;
					padding-bottom: 4px;

					>span {
						line-height: 22px;
						color: rgba(0, 0, 0, 0.7);
					}
				}

				.info-btn {
					display: flex;
					align-items: center;
					color: #00ADB5;
					margin-top: 8px;

					.van-icon {
						margin-left: 8px;
					}
				}
			}
		}
	}
</style>