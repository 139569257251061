import Vue from 'vue'
import VueRouter from 'vue-router'
import overview from '../views/overview.vue'
import search from '../views/search.vue'
import siteDetails from '../views/siteDetails.vue'
import storageDetail from '../views/storageDetail.vue'
import pvDetail from '../views/pvDetail.vue'
import pvSearch from '../views/pvSearch.vue'
import storageSeach from '../views/storageSeach.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'overview',
    component: overview
  },
  {
    path: '/search',
    name: 'search',
    component: search
  },
  {
    path: '/siteDetails',
    name: 'siteDetails',
    component: siteDetails
  },
  {
    path: '/storageDetail',
    name: 'storageDetail',
    component: storageDetail
  },
  {
    path: '/pvDetail',
    name: 'pvDetail',
    component: pvDetail
  },
  {
    path: '/pvSearch',
    name: 'pvSearch',
    component: pvSearch
  },
  {
    path: '/storageSeach',
    name: 'storageSeach',
    component: storageSeach
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
